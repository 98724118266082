@import "./styles/variables.scss";

p {
  margin: 12px 0;
}

p,
a {
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 15px;
  }
}

p,
h1,
a {
  color: $primary-text;
}

h1 {
  margin: 0;
  font-size: calc(20px + 2vmin);
}

a {
  text-decoration: none;

  &:hover,
  &:active {
    color: $secondary-text;
  }
}

.app {
  text-align: center;
  min-height: 100vh;
  padding: 0 12px;
  background: $primary-background;

  .appHeader {
    padding: 24px 0 12px 0;
    max-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .appBody {
    display: flex;
    flex-direction: column;
    align-items: center;

    .introWrapper {
      max-width: 600px;
      margin-bottom: 24px;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 15px;
      }
    }
  }

  .carouselWrapper {
    margin: 0 -12px;
    .carousel {
      max-width: 800px;

      .imageWrapper {
        overflow: hidden !important;
        width: 100%;

        @media (max-width: 600px) {
          max-width: 800px;
        }

        .image {
          max-width: 800px;
          object-fit: cover;
        }
      }
    }
  }

  footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 0;
    padding: 0 0 40px;
    flex-direction: column;

    @media (max-width: 600px) {
      padding: 0 0 20px;
    }

    .footerBottom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }

    .item {
      display: inline-flex;
      align-items: center;

      @media (max-width: 600px) {
        padding: 8px 0;
      }

      &:first-of-type {
        margin-right: 24px;
      }
    }

    .logo {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      fill: $primary-text;
    }
  }
}
