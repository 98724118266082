$light-brown-10: #eee8e0;
$light-brown-20: #c0b5a3;
$medium-brown-10: #706052;
$medium-brown-20: #5a4635;
$dark-brown-10: #44332b;
$dark-brown-80: #1d1410;
$dark-brown-90: #15100d;

$eagle: #b3b4a6;
$soya-bean: #655f51;
$soft-amber: #e8e0d2;
$lemon-grass: #d2c6b1;

$judge-gray: #5b4f39;
$lisbon-brown: #473724;
$birch: #22170b;

$primary-background: linear-gradient(to top, $lemon-grass, $soft-amber);
$primary-text: $lisbon-brown;
$secondary-text: $birch;
